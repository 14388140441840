<template>
  <CommonLayout :tabs="store.navbar.tabs" :title="store.navbar.title">
    <LoadingScreen />
    <slot></slot>
  </CommonLayout>
</template>

<script setup lang="ts">
import { useNavbarInfo } from "~/stores/useNabvarInfo";
const store = useNavbarInfo();
useHead({
  titleTemplate: "%s | Beesphone",
});
</script>
